<template>
    <div class="common-form">
        <el-form :model="ruleForm" :rules="rules" label-width="92px" ref="formRef">
            <div class="common-form-title form-info">资产信息</div>
            <el-form-item label="资产编号" class="form-info">{{ ruleForm.assetsCode || "--" }}</el-form-item>
            <el-form-item label="资产名称" class="form-info">{{ ruleForm.assetsName || "--" }}</el-form-item>
            <el-form-item label="计量单位">{{ ruleForm.assetsUnit || "--" }}</el-form-item>
            <div class="common-form-title">资产出库</div>
            <el-form-item label="维修内容" prop="fixContent">
                <el-input
                    type="textarea"
                    :rows="3"
                    maxlength="300"
                    show-word-limit
                    v-model="ruleForm.fixContent"
                    placeholder="请输入维修内容"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="维修数量" prop="operateNumber">
                <el-input
                    v-model="ruleForm.operateNumber"
                    placeholder="请输入维修数量"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="维修金额" prop="fixAmount">
                <el-input
                    v-model="ruleForm.fixAmount"
                    placeholder="请输入维修金额（元）"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="维修人" prop="fixPerson">
                <el-input
                    v-model="ruleForm.fixPerson"
                    placeholder="请输入维修人"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="申报人" prop="declarer">
                <select-tree
                    v-model="ruleForm.declarer"
                    :data="userList"
                    :props="{
                            value: 'id',
                            label: 'name',
                            children: 'children'
                        }"
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="紧急程度" prop="emergencyLevel">
                <el-select
                    v-model="ruleForm.emergencyLevel"
                    clearable
                    filterable
                    placeholder="请选择紧急程度"
                    class="input-width-medium"
                >
                    <el-option value="1" label="一般"></el-option>
                    <el-option value="2" label="紧急"></el-option>
                    <el-option value="3" label="非常紧急"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" prop="remark">
                <el-input
                    type="textarea"
                    :rows="3"
                    maxlength="300"
                    show-word-limit
                    v-model="ruleForm.remark"
                    placeholder="请输入其他内容"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="submitForm" type="primary">确定</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import selectTree from "./selectTree";

export default {
    props: {
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object,
        },
        userList: {
            type: Array,
        },
    },
    components: {
        selectTree,
    },
    data() {
        return {
            num: 20,
        };
    },
    methods: {
        submitForm() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit("submit");
                }
            });
        },
        handleClose() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped lang="scss">
.form-info {
    margin-bottom: 10px;
}
</style>
