import { render, staticRenderFns } from "./OutStorage.vue?vue&type=template&id=40d8fe35&scoped=true"
import script from "./OutStorage.vue?vue&type=script&lang=js"
export * from "./OutStorage.vue?vue&type=script&lang=js"
import style0 from "./OutStorage.vue?vue&type=style&index=0&id=40d8fe35&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d8fe35",
  null
  
)

export default component.exports