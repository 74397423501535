<template>
    <el-select
        ref="treeSelect"
        v-model="valueLabel"
        filterable
        clearable
        :placeholder="placeholder"
        :filter-method="changeValue"
        :multiple="multiple"
        :disabled="disabled"
        @focus="focus"
        @remove-tag="removeTag"
        @clear="handleClear"
    >
        <el-option :value="selectTree1">
            <vue-easy-tree
                node-key="id"
                ref="selectTree"
                :data="data"
                :check-strictly="true"
                :props="props"
                @node-click="nodeClick"
                height="200px"
                :highlight-current="true"
                :filter-node-method="filterNode"
                filterable
            >
            </vue-easy-tree>
        </el-option>
    </el-select>
</template>
<script>
import VueEasyTree from "@wchbrad/vue-easy-tree";
import "@wchbrad/vue-easy-tree/src/assets/index.scss"

export default {
    components: {
        VueEasyTree
    },
    props: {
        value: {
            type: [String, Array],
            default: '',
            require: true
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: String,
        data: Array,
        props: Object
    },
    data() {
        return {
            selectTree1: [],
            valueTitle: this.value,
            valueLabel: '',
            multipleArr: [],
            multipleLabelArr: [],
            oriData: [], // 多选回显展示数据
        };
    },
    mounted () {
        this.init();
    },
    methods: {
        init () {
            if (this.multiple && this.value.length > 0) {
                
                let ar = [];
                
                this.value.length > 0 ?
                    this.value.forEach((item) => {
                        ar.push(this.$refs.selectTree.getNode(item).data.name);
                    }) : ar = [];
                
                this.valueTitle = this.value.toString().split(',');
                this.valueLabel = ar.length > 0 ? ar.toString().split(',') : '';
                
            } else {
                this.valueTitle = this.value;
                this.valueLabel = this.$refs.selectTree.getNode(this.value) ? this.$refs.selectTree.getNode(this.value).data.name : '';
            }
        },
        
        treeFilterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        nodeClick (data, item) {
            if (!data.disabled)  {
                this.selectTree1 = [];
                
                if (this.multiple) {
                    this.multipleArr.push(data.id)
                    this.valueTitle = this.multipleArr.toString().split(',');
                    this.multipleLabelArr.push(data.name)
                    this.valueLabel = this.multipleLabelArr.toString().split(',');
                    this.$emit('input', this.valueTitle);
                } else {
                    this.valueTitle = data.id;
                    this.valueLabel = data.name;
                    this.$refs.treeSelect.blur();
                    this.$emit('input', this.valueTitle);
                    this.$emit('handleChange', data)
                }
                
            }
        },
        /**
         * changeValue 修改内容
         * */
        changeValue (value) {
            this.$refs.selectTree.filter(value);
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        removeTag (data) {
            let reTag = data,
                valIds = [];
            
            this.getMultipleChangeId(this.data, reTag, valIds);
            this.valueTitle.forEach((id, idx) => {
                valIds.forEach((item) => {
                    if (id === item) {
                        this.valueTitle.splice(idx, 1)
                    }
                })
            })
            this.$emit('input', this.valueTitle);
        },
        getMultipleChangeId (list, reTag, valIds) {
            list.forEach((item) => {
                if (item.name == reTag) {
                    valIds.push(item.id);
                }
                
                if (item[this.props.children] && item[this.props.children].length > 0) {
                    this.getMultipleChangeId (item[this.props.children], reTag, valIds)
                }
            })
        },
        focus () {
            //this.valueLabel = '';
            this.$refs.selectTree.filter('');
            // console.log(this.data,'data')
        },
        handleClear() {
            this.$emit('input', '')
            this.$emit('handleClear')  
        },
    },
    watch: {
        'valueLabel': {
            handler (newVal) {
                // console.log(newVal,this.valueLabel,'valueLabel 更新了')
            },
            deep: true
        },
        'value': {
            handler (newVal) {
                if(typeof this.value == 'string' && !this.value) {
                    this.valueLabel = '';
                }
                
                if (newVal) {
                    this.init()
                    // console.log(newVal,'getNewVal')
                }
                
                
                
            },
            deep: true
        }
    }
};
</script>
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item{
    height: auto;
    max-height: 274px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
}
</style>
