var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-form"},[_c('el-form',{ref:"formRef",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"106px"}},[_c('div',{staticClass:"common-form-title form-info"},[_vm._v("资产信息")]),_c('el-form-item',{staticClass:"form-info",attrs:{"label":"资产编号"}},[_vm._v(_vm._s(_vm.showData.assetsCode || "--"))]),_c('el-form-item',{staticClass:"form-info",attrs:{"label":"资产名称"}},[_vm._v(_vm._s(_vm.showData.assetsName || "--"))]),_c('el-form-item',{staticClass:"form-info",attrs:{"label":"计量单位"}},[_vm._v(_vm._s(_vm.showData.assetsUnit || "--"))]),_c('el-form-item',{attrs:{"label":"资产在库"}},[_vm._v(_vm._s(_vm.showData.assetsCount || 0))]),_c('div',{staticClass:"common-form-title"},[_vm._v("资产出库")]),_c('el-form-item',{attrs:{"label":"领用方类型","prop":"collectType"}},[_c('el-select',{staticClass:"input-width-medium",attrs:{"clearable":"","filterable":"","placeholder":"请选择领用方类型"},model:{value:(_vm.ruleForm.collectType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "collectType", $$v)},expression:"ruleForm.collectType"}},[_c('el-option',{attrs:{"value":"1","label":"个人"}}),_c('el-option',{attrs:{"value":"2","label":"部门"}})],1)],1),_c('el-form-item',{attrs:{"label":"费用归属","prop":"costFrom"}},[_c('el-select',{staticClass:"input-width-medium",attrs:{"clearable":"","filterable":"","placeholder":"请选择费用归属"},model:{value:(_vm.ruleForm.costFrom),callback:function ($$v) {_vm.$set(_vm.ruleForm, "costFrom", $$v)},expression:"ruleForm.costFrom"}},[_c('el-option',{attrs:{"value":"1","label":"个人"}}),_c('el-option',{attrs:{"value":"2","label":"部门"}})],1)],1),_c('el-form-item',{attrs:{"label":"领用部门","prop":"collectDept"}},[_c('select-tree',{staticClass:"input-width-medium",attrs:{"data":_vm.organList,"props":{
                        value: 'id',
                        label: 'name',
                        children: 'children'
                    }},on:{"handleChange":_vm.handleDeptChange,"handleClear":_vm.handleDeptClear},model:{value:(_vm.ruleForm.collectDept),callback:function ($$v) {_vm.$set(_vm.ruleForm, "collectDept", $$v)},expression:"ruleForm.collectDept"}})],1),_c('el-form-item',{attrs:{"label":"领用人","prop":"collectPerson","rules":[{required: _vm.ruleForm.collectType === '1', message: '请选择领用人' }]}},[_c('select-tree',{staticClass:"input-width-medium",attrs:{"data":_vm.userList,"props":{
                        value: 'id',
                        label: 'name',
                        children: 'children'
                    }},on:{"handleChange":_vm.handleUserChange,"handleClear":_vm.handleUserClear},model:{value:(_vm.ruleForm.collectPerson),callback:function ($$v) {_vm.$set(_vm.ruleForm, "collectPerson", $$v)},expression:"ruleForm.collectPerson"}})],1),_c('el-form-item',{attrs:{"label":"出库日期","prop":"operateTime"}},[_c('el-date-picker',{staticClass:"input-width-medium",attrs:{"type":"datetime","placeholder":"请选择日期","format":"yyyy-MM-dd HH:mm:ss","value-format":"yyyy-MM-dd HH:mm:ss","clearable":""},model:{value:(_vm.ruleForm.operateTime),callback:function ($$v) {_vm.$set(_vm.ruleForm, "operateTime", $$v)},expression:"ruleForm.operateTime"}})],1),_c('el-form-item',{attrs:{"label":"分配数量","prop":"operateNumber"}},[_c('el-input-number',{attrs:{"min":1,"max":_vm.showData.assetsCount || 0},model:{value:(_vm.ruleForm.operateNumber),callback:function ($$v) {_vm.$set(_vm.ruleForm, "operateNumber", $$v)},expression:"ruleForm.operateNumber"}})],1),_c('el-form-item',{attrs:{"label":"出库原因","prop":"operateReason"}},[_c('el-input',{staticClass:"input-width-medium",attrs:{"type":"textarea","rows":5,"maxlength":"300","placeholder":"请输入出库原因","clearable":""},model:{value:(_vm.ruleForm.operateReason),callback:function ($$v) {_vm.$set(_vm.ruleForm, "operateReason", $$v)},expression:"ruleForm.operateReason"}})],1),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }