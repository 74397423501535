<template>
    <div class="common-form">
        <el-form :model="ruleForm" :rules="rules" label-width="106px" ref="formRef">
            <div class="common-form-title form-info">资产信息</div>
            <el-form-item label="资产编号" class="form-info">{{ showData.assetsCode || "--" }}</el-form-item>
            <el-form-item label="资产名称" class="form-info">{{ showData.assetsName || "--" }}</el-form-item>
            <el-form-item label="计量单位" class="form-info">{{ showData.assetsUnit || "--" }}</el-form-item>
            <el-form-item label="资产在库">{{ showData.assetsCount || 0 }}</el-form-item>
            <div class="common-form-title">资产出库</div>
            <el-form-item label="领用方类型" prop="collectType">
                <el-select
                    v-model="ruleForm.collectType"
                    clearable
                    filterable
                    placeholder="请选择领用方类型"
                    class="input-width-medium"
                >
                    <el-option value="1" label="个人"></el-option>
                    <el-option value="2" label="部门"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="费用归属" prop="costFrom">
                <el-select
                    v-model="ruleForm.costFrom"
                    clearable
                    filterable
                    placeholder="请选择费用归属"
                    class="input-width-medium"
                >
                    <el-option value="1" label="个人"></el-option>
                    <el-option value="2" label="部门"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="领用部门" prop="collectDept">
                <select-tree
                    v-model="ruleForm.collectDept"
                    :data="organList"
                    :props="{
                            value: 'id',
                            label: 'name',
                            children: 'children'
                        }"
                    @handleChange="handleDeptChange"
                    @handleClear="handleDeptClear"
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="领用人" prop="collectPerson" :rules="[{required: ruleForm.collectType === '1', message: '请选择领用人' }]">
                <select-tree
                    v-model="ruleForm.collectPerson"
                    :data="userList"
                    :props="{
                            value: 'id',
                            label: 'name',
                            children: 'children'
                        }"
                    @handleChange="handleUserChange"
                    @handleClear="handleUserClear"
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="出库日期" prop="operateTime">
                <el-date-picker
                    v-model="ruleForm.operateTime"
                    type="datetime"
                    placeholder="请选择日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="分配数量" prop="operateNumber">
                <el-input-number
                    v-model="ruleForm.operateNumber"
                    :min="1"
                    :max="showData.assetsCount || 0"
                />
            </el-form-item>
            <el-form-item label="出库原因" prop="operateReason">
                <el-input
                    type="textarea"
                    :rows="5"
                    maxlength="300"
                    v-model="ruleForm.operateReason"
                    placeholder="请输入出库原因"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="submitForm" type="primary">确定</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import selectTree from "./selectTree";

export default {
    components: { selectTree },
    props: {
        showData: {
            type: Object,
        },
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object,
        },
        organList: {
            type: Array,
        },
        userList: {
            type: Array,
        },
    },
    data() {
        return {
            num: 20,
        };
    },
    methods: {
        submitForm() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit("submit");
                }
            });
        },
        handleClose() {
            this.$emit("close");
        },
        handleUserChange(data) {
            this.$emit("handleUserChange", data);
        },
        handleDeptChange(data) {
            this.$emit("handleDeptChange", data);
        },
        handleUserClear(data) {
            this.$emit("handleUserClear", data);
        },
        handleDeptClear(data) {
            this.$emit("handleDeptClear", data);
        },
    },
};
</script>

<style scoped lang="scss">
.form-info {
    margin-bottom: 10px
}
</style>
